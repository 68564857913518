<template>
    <div
        ref="selectElement"
        :class="{ select: true, disabled }"
        :tabindex="tabindex"
        :title="title && !open ? title : undefined"
        @blur="handleBlur"
    >
        <div class="selected" :class="{ open: open, error: errorMessage, 'value-selected': modelValue }" @click="openDropdown">
            <div v-if="selectedOption && selectedOption.image" class="selected__image">
                <img :src="selectedOption.image" :alt="displayName">
            </div>
            <div v-if="selectedOption && selectedOption.icon" class="selected__icon">
                <AtomIcon :name="selectedOption.icon" :width="selectedOption.iconWidth" :bolding="selectedOption.iconBolding" />
            </div>
            <div class="selected__name">
                {{ displayName }}
            </div>
            <AtomIcon class="select__dropdown-icon" name="chevron-down" />
        </div>
        <div class="items" :class="{ 'select-hide': !open }" :style="dialogStyle">
            <input
                v-if="withSearch"
                v-model="searchTerm"
                class="items__search"
                type="text"
                :placeholder="$t('Matrix.search_branchcluster')"
                @focus="keepDropdownOpen"
                @click="keepDropdownOpen"
            >
            <div
                v-for="(option, index) in filteredOptions"
                :key="index"
                class="item"
                :class="{ 'is-selected': ((option.value ?? option.name) == modelValue) }"
                @click="onClick(option)"
            >
                <div v-if="option.image" class="item__image">
                    <img :src="option.image" :alt="option.name">
                </div>
                <div v-if="option.icon" class="item__icon">
                    <AtomIcon :name="option.icon" :width="option.iconWidth" :bolding="option.iconBolding" />
                </div>
                <div class="item__name">
                    {{ option.name }}
                </div>
            </div>
        </div>
        <div v-if="errorMessage" class="error-message">
            {{ errorMessage }}
        </div>
    </div>
</template>

<script lang="ts" setup>
import type { SelectOption } from '~/composables/types/ui';

const props = withDefaults(defineProps<{
    options: SelectOption[],
    modelValue?: string | number,
    disabled?: boolean,
    errorMessage?: string,
    tabindex?: number,
    selectPlaceholder?: string,
    title?: string,
    withSearch?: boolean,
}>(), {
    disabled: false,
    tabindex: 0,
    modelValue: undefined,
    errorMessage: undefined,
    selectPlaceholder: '',
    title: '',
    withSearch: false,
});

const dialogStyle = ref('');
const blurDelay = 250;

const emit = defineEmits<{
    (e: 'update:modelValue' | 'onChange', value: SelectOption['value'] | null): void;
}>();

const { $t } = useNuxtApp();
const open = ref(false);
const selectElement = ref<HTMLElement | null>(null);
const searchTerm = ref<string>('');

const handleBlur = (event: FocusEvent) => {
    setTimeout(() => {
        const relatedTarget = event.relatedTarget as HTMLElement;
        if (!selectElement.value?.contains(relatedTarget)) {
            open.value = false;
        }
    }, blurDelay);
};

const openDropdown = () => {
    open.value = !open.value;
    if (open.value && selectElement.value) {
        dialogStyle.value = `width: ${selectElement.value.clientWidth}px;`;
    }
};

const keepDropdownOpen = (event: Event) => {
    event.stopPropagation();
};

const onClick = (option: SelectOption) => {
    const value = option.value ?? option.name;

    if (props.modelValue !== value) {
        emit('update:modelValue', value);
        emit('onChange', value);
    }

    searchTerm.value = '';
    open.value = !open.value;
};

const placeholder = computed(() => (props.selectPlaceholder ? props.selectPlaceholder : $t('General.select_placeholder')));

const selectedOption = computed(() => {
    if (props.modelValue) {
        return props.options.find(option => (option.value ?? option.name) === props.modelValue);
    }

    return null;
});

const filteredOptions = computed(() => {
    return props.options.filter(option => option.name.toLowerCase().includes(searchTerm.value.toLowerCase()));
});

const displayName = computed(() => selectedOption.value?.name ?? placeholder.value);
</script>

<style lang="scss" scoped>
.select {
    position: relative;
    width: 100%;
    min-width: rem(200);
    text-align: left;
    outline: none;
    line-height: rem(47);

    @include helper-color(gray-2);

    &__dropdown-icon {
        position: absolute;
        right: 1rem;
        transition: transform 0.3s;
        width: rem(24);
        height: rem(24);

        @include helper-color-bg(white);
        @include helper-color(state-default);
    }
}

.selected {
    padding: 0 3rem 0 sp(s);
    cursor: pointer;
    user-select: none;
    white-space: nowrap;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include helper-color-bg(white);
    @include helper-border($setting-color-gray-2, rem(1));
    @include helper-border-radius(rem(5));
    @include helper-color(black);

    &:not(.open):hover {
        @include helper-border($setting-color-state-hover, rem(1));
    }

    &.error:not(.open) {
        @include helper-border($setting-color-alert-danger, rem(1));
    }

    &.value-selected {
        @include helper-color(text-title);
    }

    &__image,
    &__icon {
        display: inline-flex;
        margin-right: sp(xs);
    }

    &__image {
        img {
            max-width: none;
            max-height: rem(32);
        }
    }

    &__name {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    }
}

.disabled {
    cursor: not-allowed;

    .value-selected {
        @include helper-color(state-disabled-text);
    }

    .selected {
        pointer-events: none;

        .select__dropdown-icon {
            @include helper-color(state-disabled-text);
        }
    }
}

.open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    &.selected .select__dropdown-icon {
        transform: rotate(180deg);
    }
}

.items {
    position: absolute;
    z-index: $setting-zi-flyout;
    max-height: rem(260);
    font-size: fs(small);
    border-radius: 0 0 rem(5) rem(5);
    margin-top: rem(-1);
    overflow: hidden auto;

    @include helper-color(gray-4);
    @include helper-border($setting-color-gray-2, rem(1));
    @include helper-color-bg(white);

    &__search {
        width: 100%;
        padding: 0 sp(s);

        @include helper-color(gray-6);
    }
}

.item {
    display: flex;
    align-items: center;
    padding-left: sp(s);
    cursor: pointer;
    user-select: none;

    @include helper-color(black);

    &:not(:first-child) {
        border-top: rem(1) solid $setting-color-gray-2;
    }

    &__image {
        display: inline-flex;
        margin-right: sp(xs);

        img {
            max-height: rem(32);
            max-width: none;
        }
    }

    &__name {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

:not(.disabled) {
    .items .item {
        &:hover {
            background-color: $setting-color-light-gray;
        }

        &.is-selected {
            @include helper-color-bg(gray-1);
            @include helper-color(black);
        }
    }
}

.error-message {
    margin-top: sp(xxs);

    @include helper-color(alert-danger);
    @include helper-font-size(smaller);
    @include helper-font-line-height(tight);
}

.select-hide {
    display: none;
}

::-webkit-scrollbar {
    width: rem(3);
}

::-webkit-scrollbar-track {
    @include helper-color-bg(white);
}

::-webkit-scrollbar-thumb {
    @include helper-color-bg(gray-2);
}

::-webkit-scrollbar-thumb:hover {
    @include helper-color-bg(gray-3);
}
</style>
